<template>
    <div class="p-grid nested-grid">
        <Toast/>
        <div class="p-col-12 p-md-6">
            <div class="card p-fluid">
                <div class="p-grid">
                    <div class="p-col-12 p-xl-8">
                        <h5>Felhasználó adatai</h5>
                        <span class="p-field p-d-block">
                            <label for="name">Név:</label>
                            <InputText id="name" v-model.trim="v$.profile.name.$model"
                                required="true" :class="{'p-invalid': v$.profile.name.$invalid}"
                            />
                            <small class="p-error" v-if="v$.profile.name.$error">{{ errorMessages.required }}</small>
                        </span>
                        <span class="p-field p-d-block">
                            <label for="email">Email:</label>
                            <InputText id="email" v-model.trim="v$.profile.email.$model"
                                required="true" :class="{'p-invalid': v$.profile.email.$invalid}"
                            />
                            <small class="p-error" v-if="v$.profile.email.required.$invalid">{{ errorMessages.required }}</small>
                            <small class="p-error" v-if="v$.profile.email.$dirty && v$.profile.email.email.$invalid">{{ errorMessages.invalidEmail }}</small>
                        </span>
                        <Toolbar class="p-mb-4 p-pl-2">
                            <template #left>
                                <Button label="Mentés" :icon="(submitted) ? 'pi pi-spin pi-spinner' : 'pi pi-check'" class="p-button-success p-mr-3" 
                                        @click="save" :disabled="v$.profile.$errors.length>0 || !v$.profile.$anyDirty" />
                                <Button label="Mégse" icon="pi pi-times" class="p-button-text" 
                                    @click="init"/>
                            </template>
                        </Toolbar>
                    </div>
                </div>
            </div>
        </div>
        <div class="p-col-12 p-md-6">
            <div class="card p-fluid">
                <div class="p-grid">
                    <div class="p-col-12 p-xl-8">
                        <h5>Jelszómódosítás</h5>
                        <span class="p-field p-d-block">
                            <label for="name">Jelenlegi jelszó:</label>
                            <InputText id="current_password" type="password" v-model="v$.pw.current_password.$model" @blur="pwServerResponse = 'OK';"
                                required="true" :class="{'p-invalid': vCheckRequire('pw','current_password')}"
                            />
                            <small class="p-error" v-if="v$.pw.current_password.$error">{{ errorMessages.required }}</small>
                            <small class="p-error" v-if="v$.pwServerResponse.$error">{{ errorMessages.invalidPassword }}</small>
                        </span>
                        <span class="p-field p-d-block">
                            <label for="password">Új jelszó:</label>
                            <InputText id="password" type="password" v-model="v$.pw.password.$model"
                                required="true"
                            />
                            <small class="p-error" v-if="v$.pw.password.$dirty && v$.pw.password.required.$invalid">{{ errorMessages.required }}</small>
                            <small class="p-error" v-if="v$.pw.password.$dirty && v$.pw.password.minLength.$invalid">{{ errorMessages.passwordMinLength }}</small>
                        </span>
                        <span class="p-field p-d-block">
                            <label for="password2">Új jelszó mégegyszer:</label>
                            <InputText id="password2" type="password" v-model="v$.pw.password2.$model" 
                                required="true"
                            />
                            <small class="p-error" v-if="v$.pw.password2.$dirty && v$.pw.password2.required.$invalid">{{ errorMessages.required }}</small>
                            <small class="p-error" v-if="v$.pw.password2.$dirty && !v$.pw.password2.match.$invalid">{{ errorMessages.passwordsNotMatch }}</small>
                        </span>
                        <Toolbar class="p-mb-4 p-pl-2">
                            <template #left>
                                <Button label="Módosít" :icon="(submitted) ? 'pi pi-spin pi-spinner' : 'pi pi-check'" class="p-button-success p-mr-3" 
                                        @click="savePassword" :disabled="v$.pw.$errors.length>0 || !v$.pw.$anyDirty" />
                                <Button label="Mégse" icon="pi pi-times" class="p-button-text" 
                                    @click="init"/>
                            </template>
                        </Toolbar>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { store } from '@/store/'
import UserService from '@/service/backend/user.service'
import useVuelidate from '@vuelidate/core'
import { sameAs, minLength, requiredIf } from '@vuelidate/validators'
import { required, email, errorMessages } from  '@/texts/errorMessages'

export default {
    setup: () => ({ v$: useVuelidate() }),
    validationConfig: {
        $lazy: true,
        $autoDirty: true
    },
    data() {
        return {
            submitted: false,
            errorMessages,
            profile: { 
                name: null, 
                email: null
            },
            pw: {
                current_password: null, 
                password: null, 
                password2: null
            },
            pwServerResponse: "OK"
        }
    },
    validations: {
        profile: {
            name: { required },
            email: { required, email }
        },
        pw: {
            current_password: { 
                required: requiredIf(function(){
                    return !!(this.pw.password || this.pw.password2)
                })
            }, 
            password: { 
                required: requiredIf(function(){
                    return !!this.pw.password2
                }), 
                minLength: minLength(8) 
            }, 
            password2: { 
                required: requiredIf(function(){
                    return !!this.pw.password
                }), 
                match: sameAs(function() {
                    return this.pw.password // TODO, sajnos nem működik, nem is lehet jelszót váltani. :( 
                }) 
            }
        },
        pwServerResponse: { required }
    },
    methods: {
        init(){
            this.profile.name = this.user.name 
            this.profile.email = this.user.email
            this.pw = {} 
            this.pwServerResponse = "OK";
            this.v$.$reset()
        },
        vCheckRequire(fieldset, field){
            return this.v$[fieldset][field].$dirty && this.v$[fieldset][field].required.$invalid
        },
        async save() {

            if (!this.v$.profile.$invalid) {
                this.submitted = true
                try{
                    const data = { name: this.profile.name, email: this.profile.email }
                    await store.dispatch('user/updateUserData', data)
                    this.v$.$reset()
                    this.$toast.add({severity:'success', summary: 'Mentve!', detail: 'Felhasználói adatok frissítve!', life: 3000 })
                }catch{
                    this.$toast.add({severity:'error', summary: 'Hiba!', detail: 'Nem sikerült a mentés!', life: 3000 })
                }finally{
                    this.submitted = false
                }
            }
        },
        async savePassword() {

            if (!this.v$.pw.$invalid) {
                this.submitted = true
                try{
                    const passwords = { current_password: this.pw.current_password, password: this.pw.password, password_confirmation: this.pw.password2 }
                    await UserService.updateUserPassword(passwords)
                    if (window.PasswordCredential) {
                        const passwordCredential = new window.PasswordCredential({ id: this.user.email, password: passwords.password });
                        window.navigator.credentials.store(passwordCredential);
                    }
                    this.init()
                    this.$toast.add({severity:'success', summary: 'Mentve!', detail: 'Mostantól az új jelszóval jelentkezhet be!', life: 3000 })
                }catch(error){
                    let message = "Hibás jelszó-adatok!"
                    if(error.response.status === 422 && 
                        error.response.data.errors.current_password[0] &&
                        error.response.data.errors.current_password[0] === "The provided password does not match your current password."){
                        this.pwServerResponse = ""; this.v$.pwServerResponse.$touch()
                        message = this.errorMessages.invalidPassword
                    }
                    this.pwServerResponse = ""
                    this.v$.pwServerResponse.$touch()
                    this.$toast.add({severity:'error', summary: 'Hiba!', detail: message, life: 3000 })
                }finally{
                    this.submitted = false
                }
            }
        }
    },
    computed: {
        ...mapGetters({
            user: 'user/User'
        }),
    },
    beforeMount() {
        this.init()
    },
    beforeRouteLeave(to, from, next) {
        this.pw = {}
        next()
    }
}
</script>
